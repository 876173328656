var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"d-sm-flex justify-content-between align-items-center "},[_c('div',{staticClass:"font-weight-bolder"},[_c('h3',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticStyle:{"width":"70%"}},[_c('div',{staticClass:"d-flex align-items-center flex-wrap flex-sm-nowrap w-100 dashboard_style"},[_c('date-picker',_vm._b({staticClass:" w-100",staticStyle:{"margin-bottom":"-0.5rem"},on:{"change":_vm.datePickerHandler},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'date-picker',_vm.datePickerOptions,false)),_c('field',{staticClass:"ml-sm-1 w-100",attrs:{"field":{
                  key: 'customergroup_id',
                  type: 'list',
                  required: true,
                  list: 'customergroup',
                  listLabel: 'customergroup_name',
                },"entity":_vm.entity}}),_c('field',{staticClass:"ml-sm-1 w-100",attrs:{"field":{
                  key: 'company_id',
                  filter_key: 'customergroup_id',
                  type: 'list',
                  required: false,
                  list: 'company',
                  listLabel: 'company_name',
                },"entity":_vm.entity}}),_c('field',{staticClass:" mx-sm-1 w-100",attrs:{"field":{ key: 'team_id', type: 'custom-select', required: false, items: _vm.filteredTeams, noAutoFill: !_vm.team_is_customer, disabled: _vm.teamFieldDisabled },"entity":_vm.entity}}),_c('field',{staticClass:"w-100",attrs:{"field":{ key: 'user_id', type: 'custom-select', items: _vm.usersData, required: false },"entity":_vm.entity,"disabled":_vm.entity.company_id === -1}})],1)])]),_c('b-row',[_c('summary-card',{attrs:{"loading":_vm.loading,"title":_vm.$t('headline~dashboard~subframe~open_tickets_intime'),"color":"#008000","percent":(_vm.before_deadline*100/_vm.total_open_tickets).toFixed(0),"number":_vm.before_deadline,"variant":"dark","cols":"4"},nativeOn:{"click":function($event){return _vm.show(_vm.dashboard_filter.BEFORE_DEADLINE)}}}),_c('summary-card',{attrs:{"loading":_vm.loading,"title":_vm.$t('headline~dashboard~subframe~open_tickets_afteryellow'),"color":"#d1bf00","percent":(_vm.critical_yellow*100/_vm.total_open_tickets).toFixed(0),"number":_vm.critical_yellow,"variant":"warning","cols":"4"},nativeOn:{"click":function($event){return _vm.show( _vm.dashboard_filter.CRITICAL_YELLOW)}}}),_c('summary-card',{attrs:{"loading":_vm.loading,"title":_vm.$t('headline~dashboard~subframe~open_tickets_afterred'),"color":"#d70000","percent":(_vm.over_due_red*100/_vm.total_open_tickets).toFixed(0),"number":_vm.over_due_red,"variant":"danger","cols":"4"},nativeOn:{"click":function($event){return _vm.show(_vm.dashboard_filter.OVERDUE_RED)}}}),_c('summary-card',{attrs:{"loading":_vm.loading,"hide-percentage":true,"title":_vm.$t('headline~dashboard~subframe~update~tickets~numbers'),"color":"#1183D5","number":_vm.total_update_ticket,"variant":"dark","cols":"4"},nativeOn:{"click":function($event){return _vm.show('Update_ticket')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }